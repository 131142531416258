import * as Yup from "yup";
import moment from "moment";

const currentYear = moment().year();

const betweenTwonNumbers = (min: number, max: number) => {
  return Yup.number()
    .min(min, `Must be ${min} or greater`)
    .max(max, `Must be ${max} or less`)
    .required("Required");
};

const validatePercentage = betweenTwonNumbers(0, 100);

const greaterOrEqualToNumber = (num: number) => {
  return Yup.number()
    .min(num, `Must be ${num} or greater`)
    .required("Required");
};

const positiveNumber = greaterOrEqualToNumber(0);

export const validationSchema = (
  type: string,
  hasMaxVaults: boolean,
  hasMaxLtcs: boolean,
) => {
  return Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(30, "Too Long!").nullable(),
    segmentType: Yup.string()
      .oneOf(
        ["annuity", "vault", "iul", "ltc", "roth_conv", "part_roth"],
        "Invalid segment type",
      )
      .test("vault_limit", "Vault limit reached", (value) => {
        return !(value === "vault" && hasMaxVaults);
      })
      .test("ltc_limit", "LTC limit reached", (value) => {
        return !(value === "ltc" && hasMaxLtcs);
      })
      .required("Required"),
    segmentData: Yup.object()
      .when("segmentType", {
        is: (val: string) => val === "annuity",
        then: (schema) => schema.shape(annuityValidationSchema),
      })
      .when("segmentType", {
        is: (val: string) => val === "vault",
        then: (schema) => schema.shape(vaultValidationSchema),
      })
      .when("segmentType", {
        is: (val: string) => val === "iul",
        then: (schema) => schema.shape(iulValidationSchema),
      })
      .when("segmentType", {
        is: (val: string) => val === "ltc",
        then: (schema) => schema.shape(ltcValidationSchema),
      })
      .when("segmentType", {
        is: (val: string) => val === "roth_conv",
        then: (schema) => schema.shape(rothConvValidationSchema),
      })
      .when("segmentType", {
        is: (val: string) => val === "part_roth",
        then: (schema) => schema.shape(partRothConvValidationSchema),
      }),
  });
};

const annuityValidationSchema = {
  totalPremium: positiveNumber,
  inflationRate: validatePercentage,
  // payoutMethod: Yup.string().oneOf(['income_rider', 'annuitization'], 'Select one').required('Required'),
  startYear: betweenTwonNumbers(1, 100),
  monthlyIncome: positiveNumber,
  inflationAdjustedMonthlyIncome: positiveNumber,
  bonusType: Yup.string()
    .oneOf(["premium", "income"], "Select one")
    .required("Required"),
  bonusRate: validatePercentage,
  rollupRate: Yup.number().when("bonusType", {
    is: (val: string) => val === "income",
    then: (schema) => validatePercentage,
  }),
  annualGrowthRate: validatePercentage,
  withdrawalRate: validatePercentage,
  qualified: Yup.number().oneOf([0, 1], "Select one").required("Required"),
};

const vaultValidationSchema = {
  startingBalance: positiveNumber,
  annualGrowthRate: validatePercentage,
};

const iulValidationSchema = {
  totalPremium: Yup.number()
    .min(0, "Please upload a file to parse")
    .required("Required"),
  // annualIncome: Yup.number().min(0, 'Must be 0 or greater').required('Required'),
  annualGrowthRate: validatePercentage,
  parsedFile: Yup.array(
    Yup.object().shape({
      age: betweenTwonNumbers(18, 130),
      premium: Yup.number(),
      annualIncome: Yup.number(),
      surrenderValue: Yup.number(),
      accumulationValue: Yup.number(),
      deathBenefit: Yup.number(),
    }),
  )
    .min(1, "Please upload a file to parse")
    .when("totalPremium", {
      is: (val: number) => isNaN(val),
      then: (schema) => schema.required("Please upload a file to parse"),
    }),
  // startYear: Yup.number().min(currentYear, `Must be ${currentYear} or greater`).required('Required'),
  // endYear: Yup.number().min(currentYear, `Must be ${currentYear} or greater`).required('Required'),
};

const ltcValidationSchema = {
  totalPremium: positiveNumber,
  monthlyIncome: positiveNumber,
  startAge: betweenTwonNumbers(18, 110),
  term: betweenTwonNumbers(1, 40),
  // totalBenefit: Yup.number().min(0, 'Must be 0 or greater').required('Required'),
  deathBenefit: positiveNumber,
};

const rothConvValidationSchema = {
  totalPremium: positiveNumber,
  taxesPaid: positiveNumber,
  taxPaidStartYear: greaterOrEqualToNumber(currentYear),
  taxPaidEndYear: greaterOrEqualToNumber(currentYear),
  annualGrowthRate: validatePercentage,
  income: positiveNumber,
  incomeStartYear: greaterOrEqualToNumber(currentYear),
  incomeEndYear: greaterOrEqualToNumber(currentYear),
};

const partRothConvValidationSchema = {
  totalPremium: positiveNumber,
  startYear: positiveNumber,
  conversionYears: greaterOrEqualToNumber(1),
  taxesPaid: validatePercentage,
  annualGrowthRate: validatePercentage,
  feeRate: validatePercentage,
  bonusRate: validatePercentage,
};

import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DragDropContext } from "react-beautiful-dnd";
import {
  ConfirmModal,
  PreviewModal,
  Spin,
  StrictModeDroppable,
} from "components";
import { useParams } from "react-router-dom";
import {
  BucketReportSegment,
  ConfirmModalRef,
  PreviewModalRef,
  SegmentType,
} from "types";
import clsx from "clsx";
import { maxBy, isUndefined, filter } from "lodash";
import { ReportSegment } from "./report_segment";
import { toast } from "react-toastify";
import { useApi } from "api";

interface ReportSegmentsProps {
  segmentTypes: SegmentType[];
}
export const ReportSegments: React.FC<ReportSegmentsProps> = (props) => {
  const { segmentTypes } = props;
  const api = useApi();
  const params = useParams<{
    agencyId: string;
    advisorId: string;
    clientId: string;
    reportId: string;
  }>();
  const [editingSegment, setEditingSegment] = useState<string | undefined>(
    undefined,
  );
  const [isDragging, setIsDragging] = useState(false);
  // const [segmentData, setSegmentData] = useState<BucketReportSegment[]>([]);
  const queryClient = useQueryClient();

  const confirmModalRef = useRef<ConfirmModalRef>(null);
  const previewModalRef = useRef<PreviewModalRef>(null);

  const addSegment = useMutation({
    mutationFn: async ({
      clientId,
      reportId,
      values,
    }: {
      clientId: string;
      reportId: string;
      values: BucketReportSegment;
    }) => {
      return await api.post(
        `/api/v1/clients/${clientId}/bucket-reports/${reportId}/segments/`,
        values,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
          params.clientId,
          "report",
          params.reportId,
          "segment",
        ],
      });
      toast.success("Segment added!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(`Error adding segment: ${error.message}`);
    },
  });

  const updateSegment = useMutation({
    mutationFn: async ({
      clientId,
      reportId,
      segmentId,
      values,
    }: {
      clientId: string;
      reportId: string;
      segmentId: string;
      values: BucketReportSegment;
    }) => {
      return await api.put(
        `/api/v1/clients/${clientId}/bucket-reports/${reportId}/segments/${segmentId}/`,
        values,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
          params.clientId,
          "report",
          params.reportId,
          "segment",
        ],
      });
      toast.success("Segment updated!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(`Error updating segment: ${error.message}`);
    },
  });

  const swapSegment = useMutation({
    mutationFn: async ({
      clientId,
      reportId,
      values,
    }: {
      clientId: string;
      reportId: string;
      values: { sourceId: string; destinationId: string };
    }) => {
      return await api.put(
        `/api/v1/clients/${clientId}/bucket-reports/${reportId}/segments/swap/`,
        values,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
          params.clientId,
          "report",
          params.reportId,
          "segment",
        ],
      });
    },
  });

  const deleteSegment = useMutation({
    mutationFn: async (segmentId: string) => {
      return await api._delete(
        `/api/v1/clients/${params.clientId}/bucket-reports/${params.reportId}/segments/${segmentId}/`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
          params.clientId,
          "report",
          params.reportId,
          "segment",
        ],
      });
      setEditingSegment(undefined);
      toast.success("Segment deleted!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error("Error deleting segment");
    },
  });

  const segments = useQuery<BucketReportSegment[]>({
    queryKey: [
      "agency",
      params.agencyId,
      "advisor",
      params.advisorId,
      "client",
      params.clientId,
      "report",
      params.reportId,
      "segment",
    ],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await api.get<BucketReportSegment[]>(
        `/api/v1/clients/${params.clientId}/bucket-reports/${params.reportId}/segments/`,
      );
      return response.data;
    },
  });

  const onDragEnd = (result: any) => {
    if (!result.destination || !segments.data) {
      setIsDragging(false);
      return;
    }
    const sourceItem = segments.data[result.source.index];
    const destinationItem = segments.data[result.destination.index];
    const items = Array.from(segments.data);
    items[result.source.index] = items.splice(
      result.destination.index,
      1,
      sourceItem,
    )[0];
    queryClient.setQueryData(
      [
        "agency",
        params.agencyId,
        "advisor",
        params.advisorId,
        "client",
        params.clientId,
        "report",
        params.reportId,
        "segment",
      ],
      () => items,
    );

    swapSegment.mutate({
      clientId: params.clientId || "",
      reportId: params.reportId || "",
      values: { sourceId: sourceItem.id, destinationId: destinationItem.id },
    });

    setIsDragging(false);
  };

  const handleSegmentEdit = (segmentId: string) => {
    setEditingSegment(segmentId);
  };

  const hasRouteParams =
    !isUndefined(params.agencyId) &&
    !isUndefined(params.advisorId) &&
    !isUndefined(params.clientId) &&
    !isUndefined(params.reportId);

  const hasMaxVaults =
    filter(segments.data, (segment) => segment.segmentType === "vault").length >
    0;
  const hasMaxLtcs =
    filter(segments.data, (segment) => segment.segmentType === "ltc").length >
    1;

  return (
    <>
      {!hasRouteParams ||
      !segments.data ||
      segments.isLoading ||
      !segmentTypes ? (
        <Spin size="4xl" />
      ) : (
        <DragDropContext
          onDragEnd={onDragEnd}
          onBeforeCapture={() => setIsDragging(true)}
        >
          <ConfirmModal ref={confirmModalRef} titleText="delete segment" />
          <PreviewModal ref={previewModalRef} />
          <StrictModeDroppable
            droppableId="droppable-segments"
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <div
                className={clsx(
                  "flex h-full w-full flex-row justify-start overflow-x-scroll overscroll-contain bg-gray-400 pb-2 pr-2",
                  snapshot.isDraggingOver && "bg-gray-200",
                )}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {segments.data.map((segment, index) => (
                  <ReportSegment
                    key={`segment-${segment.id}`}
                    segment={segment}
                    segmentTypes={segmentTypes}
                    hasMaxVaults={hasMaxVaults}
                    hasMaxLtcs={hasMaxLtcs}
                    index={index}
                    deleteSegment={() => deleteSegment.mutate(segment.id)}
                    confirmModalRef={confirmModalRef}
                    previewModalRef={previewModalRef}
                    editingSegment={editingSegment}
                    handleSegmentEdit={handleSegmentEdit}
                    cancelEditSegment={() => {
                      setEditingSegment(undefined);
                    }}
                    cancelAddSegment={() => {
                      queryClient.setQueryData(
                        [
                          "agency",
                          params.agencyId,
                          "advisor",
                          params.advisorId,
                          "client",
                          params.clientId,
                          "report",
                          params.reportId,
                          "segment",
                        ],
                        (old: BucketReportSegment[]) => old.slice(0, -1),
                      );
                      setEditingSegment(undefined);
                    }}
                    handleSegmentSubmit={(values, formikHelpers) => {
                      if (hasRouteParams) {
                        if (values.id.toString() === "new") {
                          addSegment.mutate(
                            {
                              clientId: params.clientId,
                              reportId: params.reportId,
                              values,
                            },
                            {
                              onSuccess: () => {
                                setEditingSegment(undefined);
                                formikHelpers.setSubmitting(false);
                              },
                            },
                          );
                        } else {
                          updateSegment.mutate(
                            {
                              clientId: params.clientId,
                              reportId: params.reportId,
                              segmentId: values.id,
                              values,
                            },
                            {
                              onSuccess: () => {
                                setEditingSegment(undefined);
                                formikHelpers.setSubmitting(false);
                              },
                            },
                          );
                        }
                      }
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
          <div className="w-min-16 my-1 ml-1 h-full w-16 flex-none bg-white p-2.5">
            <div className="flex h-full flex-row justify-center">
              <button
                disabled={!isUndefined(editingSegment) || isDragging}
                className={clsx(
                  "text-center text-gray-400",
                  isUndefined(editingSegment) && "hover:text-teal-200",
                )}
                onClick={() => {
                  queryClient.setQueryData(
                    [
                      "agency",
                      params.agencyId,
                      "advisor",
                      params.advisorId,
                      "client",
                      params.clientId,
                      "report",
                      params.reportId,
                      "segment",
                    ],
                    (old: BucketReportSegment[]) => [
                      ...old,
                      {
                        id: "new",
                        name: "",
                        segmentTypes,
                        segmentData: {},
                        position:
                          (maxBy(segments.data, "position")?.position || 0) + 1,
                        createdOn: "",
                        modifiedOn: "",
                      } as BucketReportSegment,
                    ],
                  );
                  setEditingSegment("new");
                }}
              >
                <FontAwesomeIcon icon={["fas", "plus-square"]} size="3x" />
              </button>
            </div>
          </div>
        </DragDropContext>
      )}
    </>
  );
};
